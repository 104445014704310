export const useStackedModals = () => {
  const route = useRoute()

  const moreParams = computed(() => {
    return queryValueToArray(route.query.more)
  })

  const closeTopModal = async () => {
    const more = queryValueToArray(route.query.more)

    more.pop()

    if (more.length === 0) {
      await navigateTo({ query: omit('more', route.query) })
    }
    else {
      await navigateTo({ query: { ...route.query, more } })
    }
  }

  const openModal = async (identifier: string) => {
    if (moreParams.value.includes(identifier)) {
      return
    }

    await navigateTo({ query: { ...route.query, more: [...moreParams.value, identifier] } })
  }

  const lastModal = computed(() => moreParams.value.length > 0 ? moreParams.value[moreParams.value.length - 1] : null)

  return {
    moreParams,
    openModal,
    closeTopModal,
    lastModal,
  }
}
